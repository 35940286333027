import bounds from '@/reducers/bounds'
import content from '@/reducers/content'
import form from '@/reducers/form'
import gdrive from '@/reducers/gdrive'
import layer from '@/reducers/layer'
import layout from '@/reducers/layout'
import loading from '@/reducers/loading'
import locale from '@/reducers/locale'
import nav from '@/reducers/nav'
import options from '@/reducers/options'
import routing from '@/reducers/routing'

export default {
  bounds,
  content,
  form,
  gdrive,
  layer,
  layout,
  loading,
  locale,
  nav,
  options,
  routing,
}
