import { useState } from 'react'
import { createContext } from 'use-context-selector'

const Context = createContext()
const { Provider, Consumer } = Context

const ContextComponent = ({
  children,
}) => {
  // const [isBackButtonVisible, setBackButtonVisible] = useState(false)
  // const [backButtonLabel, setBackButtonLabel] = useState('')
  // const [backButtonLink, setBackButtonLink] = useState('')
  const [backButtonOnClick, setBackButtonOnClick] = useState('')
  const setBackButtonFunc = (fn) => setBackButtonOnClick(() => fn)
  const [newsScrollInit, setNewsScrollInit] = useState(0)
  const [isNewsExiting, setNewsExiting] = useState(false)

  return (
    <Provider value={{
      // isBackButtonVisible,
      // setBackButtonVisible,
      // setBackButtonLabel,
      // backButtonLabel,
      // setBackButtonLink,
      // backButtonLink,
      setBackButtonFunc,
      backButtonOnClick,
      setNewsScrollInit,
      newsScrollInit,
      isNewsExiting,
      setNewsExiting,
    }}
    >
      {children}
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ContextComponent
