import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

const colors = [
  '#000000', // #0 Dark
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#005798', // #3 Blue Text
  '#4da1e0', // #4 Light Blue
  '#6e6e6e', // #5 Grey
  '#eae8e4', // #6 Medium Grey
  '#f4f2f2', // #7 Light Grey
  '#28282a', // #8 Dark Blue
  '#181818', // #9 Blue Background
  '#eac02d', // #10 Yellow Selection CSS

]

const linktreeTheme = {
  backgroundColor: '',
}

const fonts = [
  '"Helvetica Neue", Helvetica, Arial, sans-serif', // [0]
  '"Matter", "Helvetica Neue", Helvetica, Arial, sans-serif', // [1]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
  garret: 'cubic-bezier(0.5, 0, 0, 1);', // [3]
}

const general = {
  fontFamily: fonts[0],
  fontSize: 18,
  lineHeight: 1.333,
  color: colors[4],
}

const zindex = {
  overlay: 101,
  mainNav: 102,
  header: 103,
  cursor: 102,
  modal: 103,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const headings = {
  fontFamily: fonts[1],
  fontWeight: 'normal',
  lineHeight: 1.2,
  margin: 0,
  padding: 0,
  color: colors[3],
  '& strong, & b': {
    fontWeight: 'bold',
  },
  '& p': {
    margin: 0,
  },
}

const pretitle = {
  fontSize: 12,
  letterSpacing: '1px',
  fontFamily: fonts[1],
  textTransform: 'uppercase',
}

const typography = {
  general: {
    extend: general,
  },
  headings: {
    extend: headings,
  },
}

const wrapper = {
  paddingLeft: '4.2vw',
  paddingRight: '4.2vw',
  [mediaInverse.sm]: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}

const smallWrapper = {
  paddingLeft: '7vw',
  paddingRight: '7vw',
}

const link = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(1)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(0)',
        transformOrigin: '0 0',
      },
    },
  },
}

const linkInverse = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
    },
  },
}

export const theme = {
  media,
  mediaInverse,
  colors,
  linktreeTheme,
  zindex,
  wrapper,
  smallWrapper,
  easings,
  fonts,
  pretitle,
  typography,
  link,
  linkInverse,
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 20,
    bottom: 20,
    logo: {
      width: 150,
      height: 46,
      fill: colors[3],
    },
    burger: {
      wrap: 45,
      width: 15,
      height: 10,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
}
