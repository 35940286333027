import { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import Modernizr from 'modernizr'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import Handlers from '@/components/Handlers'
import BannerIE11 from '@/components/BannerIE11'
import Routes from '@/router'
import Layout from '@/components/Layout'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/options'
import useViewport from '@/hooks/useViewport'
import { theme } from '@/styles/style'

const App = () => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { routes } = useSelector((state) => ({
    routes: state.routing.routes,
  }), shallowEqual)

  const { vw, vh, vwfixed, vhfixed } = useViewport()

  useEffect(() => {
    document.documentElement.style.setProperty('--vw', `${vw}px`)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
    document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)
  }, [vw, vh, vwfixed, vhfixed])

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const receiveRouting = useCallback((data) => dispatch(routingActions.receiveRouting(data)), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])

  useEffect(() => {
    if (window.routing) {
      receiveRouting(window.routing)
    } else {
      fetchRouting()
    }
    fetchOptions()
  }, [])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <ScrollbarContext>
            <Layout>
              {Modernizr.ie11 && <BannerIE11 />}
              <Routes routes={routes} />
            </Layout>
          </ScrollbarContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
