import { memo } from 'react'
import Helmet from 'react-helmet'
import { decodeEntities } from '@/utils/decodeEntities'

const Meta = ({
  title,
  meta,
  schema,
}) => {
  return (
    <Helmet
      title={`${decodeEntities(title)}`}
      meta={meta}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

Meta.defaultProps = {
  title: '',
  meta: [],
  schema: [],
}

export default memo(Meta)
