import { memo, useRef, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import SmoothScroll from '@/components/SmoothScroll'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import LinktreeGrid from '@/components/LinktreeGrid'
import LinktreeSocials from '@/components/LinktreeSocials'
import LinktreeLinks from '@/components/LinktreeLinks'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import style from './style'

import { theme } from '../../styles/style'

const useStyles = createUseStyles(style)

const HomePage = ({
  stub: stubFromProps,
}) => {
  const stub = useRef(stubFromProps)
  const [isDataFetched, setDataFetched] = useState(false)
  const [theme, setTheme] = useState(null)
  const $root = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    isPageAnimationReady: state.loading.isPageAnimationReady,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Did Mount (Fetch Data)
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage(stub.current)
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      setDataFetched(true)
      setTheme(page.acf.theme_customizer)
    }
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => {},
  })

  const classes = useStyles({ theme })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = useCallback(() => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }, [load, page])

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        customTheme={theme}
        image={page.acf.hero.logo.url}
        name={page.acf.hero.name}
        slogan={page.acf.hero.slogan}
      />
    )
  }

  /*--------------------
  Render Linktree Grid
  --------------------*/
  const renderLinktreeGrid = () => {
    return Object.keys(page).length > 0 && (
      <LinktreeGrid
        customTheme={theme}
        elements={page.acf}
      />
    )
  }

  /*--------------------
  Render Linktree Links
  --------------------*/
  const renderLinktreeLinks = () => {
    return Object.keys(page).length > 0 && (
      <LinktreeLinks
        customTheme={theme}
        elements={page.acf.linktree.linktree_list}
      />
    )
  }

  /*--------------------
  Render Linktree Socials
  --------------------*/
  const renderLinktreeSocials = () => {
    return Object.keys(page).length > 0 && (
      <LinktreeSocials
        customTheme={theme}
        elements={page.acf.socials}
      />
    )
  }

  return (
    <SmoothScroll
      init={load}
      className={`page pageHome ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {load && (
        <>
          {renderHero()}
          {renderLinktreeGrid()}
          {renderLinktreeLinks()}
          {renderLinktreeSocials()}
        </>
      )}
    </SmoothScroll>
  )
}

export default memo(HomePage)
