import { RECEIVE_FORM } from '@/actions/types'

const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FORM:
      state = {
        ...state,
        [action.key]: {
          fields: action.payload.form,
          title: action.payload.title,
        },
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
