const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      userSelect: 'none',
      background: (props) => `${props.theme?.background_color}`,
    },
    page: {},
    wrapper: {
      extend: theme.smallWrapper,
    },

  }

  return output
}

export default style
