import { memo } from 'react'

const SVGSprite = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol id="ico-close" viewBox="0 0 20 20">
        <path d="M20 1.42L18.58 0 10 8.58 1.42 0 0 1.42 8.58 10 0 18.58 1.42 20 10 11.42 18.58 20 20 18.58 11.42 10z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 24.22 45.88">
        <path d="M0 22.94l1.69 1.94 18 21L24.22 42 7.88 22.94 24.22 3.88 19.69 0l-18 21z" />
      </symbol>
      <symbol id="ico-arrow-right" viewBox="0 0 5.952 9.819">
        <path d="M3.564 4.904L0 8.619l1.251 1.2 4.7-4.916-4.7-4.9L0 1.203z" />
      </symbol>
      <symbol id="ico-arrow-right-triangular" viewBox="0 0 24 24">
        <path d="M21 12L3 24V0z" />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 31.5 36.37">
        <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
      </symbol>
      <symbol id="ico-fullscreen" viewBox="0 0 17 17">
        <path d="M0 7V0h7v2H2v5zm10-7h7v7h-2V2h-5zm7 10v7h-7v-2h5v-5zM7 17H0v-7h2v5h5z" />
      </symbol>
      <symbol id="ico-arrow" viewBox="0 0 53.75 26.5">
        <path d="M52.25 12.25H5.49l9.69-9.69A1.5 1.5 0 1013.06.44L0 13.5l12.56 12.56a1.5 1.5 0 002.12 0 1.49 1.49 0 000-2.12L6 15.25h46.25a1.5 1.5 0 000-3z" />
      </symbol>
      <symbol id="ico-facebook" viewBox="0 0 6.4 12">
        <path d="m6 6.8.3-2.2h-2V3.2c-.1-.6.3-1.1.9-1.2h1.1V.2C5.9.1 5.3 0 4.7 0 3.3-.1 2 1 1.9 2.4v2.2H0v2.2h1.9V12h2.3V6.8H6z" />
      </symbol>
      <symbol id="ico-instagram" viewBox="0 0 12 11.997">
        <path d="M6.005 2.922a3.076 3.076 0 1 0 3.072 3.076 3.071 3.071 0 0 0-3.072-3.076Zm0 5.076a2 2 0 1 1 2-2 2 2 0 0 1-2 2Zm3.919-5.2a.717.717 0 1 1-.719-.719.716.716 0 0 1 .715.718Zm2.037.728a3.551 3.551 0 0 0-.969-2.514 3.574 3.574 0 0 0-2.517-.97c-.991-.056-3.959-.056-4.95 0a3.569 3.569 0 0 0-2.514.966 3.562 3.562 0 0 0-.969 2.514c-.056.991-.056 3.959 0 4.95a3.551 3.551 0 0 0 .969 2.514 3.578 3.578 0 0 0 2.514.969c.991.056 3.959.056 4.95 0a3.55 3.55 0 0 0 2.514-.969 3.574 3.574 0 0 0 .969-2.514c.056-.991.056-3.957 0-4.947Zm-1.28 6.01a2.025 2.025 0 0 1-1.14 1.14 13.222 13.222 0 0 1-3.536.24 13.325 13.325 0 0 1-3.536-.241 2.025 2.025 0 0 1-1.14-1.14 13.222 13.222 0 0 1-.241-3.536 13.325 13.325 0 0 1 .236-3.537 2.025 2.025 0 0 1 1.141-1.14 13.222 13.222 0 0 1 3.54-.241 13.325 13.325 0 0 1 3.536.241 2.025 2.025 0 0 1 1.14 1.14 13.222 13.222 0 0 1 .241 3.536 13.214 13.214 0 0 1-.244 3.537Z" />
      </symbol>
      <symbol id="ico-youtube" viewBox="0 0 17.067 12">
        <path d="M16.71 1.878A2.144 2.144 0 0 0 15.201.359 50.678 50.678 0 0 0 8.533 0a50.678 50.678 0 0 0-6.668.359A2.144 2.144 0 0 0 .357 1.878 22.5 22.5 0 0 0 0 6.013a22.5 22.5 0 0 0 .357 4.135 2.113 2.113 0 0 0 1.509 1.494 50.679 50.679 0 0 0 6.668.359 50.678 50.678 0 0 0 6.668-.359 2.113 2.113 0 0 0 1.509-1.494 22.5 22.5 0 0 0 .357-4.135 22.5 22.5 0 0 0-.357-4.135ZM6.788 8.55V3.475l4.462 2.537L6.788 8.55Z" />
      </symbol>
      <symbol id="ico-linkedin" viewBox="0 0 12 12">
        <path d="M2.686 11.999H.2V3.988h2.486Zm-1.245-9.1a1.448 1.448 0 1 1 1.441-1.458 1.453 1.453 0 0 1-1.441 1.458ZM12 11.999H9.515v-3.9c0-.929-.019-2.121-1.293-2.121-1.293 0-1.492 1.01-1.492 2.054v3.967H4.244V3.988h2.387v1.093h.035a2.614 2.614 0 0 1 2.353-1.294C11.537 3.787 12 5.445 12 7.599v4.4Z" />
      </symbol>
      <symbol id="ico-pinterest" viewBox="0 0 50 50">
        <path d="M25 0a25 25 0 1025 25A25 25 0 0025 0zm2.58 30.57a4.13 4.13 0 01-3.51-1.79s-.83 3.31-1 3.94a15.23 15.23 0 01-2.59 4.7.19.19 0 01-.34-.08 16 16 0 010-5.46c.28-1.16 1.85-7.82 1.85-7.82a5.52 5.52 0 01-.46-2.27c0-2.13 1.24-3.72 2.77-3.72a1.92 1.92 0 011.94 2.16c0 1.31-.84 3.27-1.27 5.09a2.22 2.22 0 002.27 2.77c2.72 0 4.55-3.5 4.55-7.64 0-3.14-2.12-5.5-6-5.5a6.81 6.81 0 00-7.07 6.88 4.11 4.11 0 00.95 2.81.72.72 0 01.21.81c-.07.26-.23.9-.29 1.15a.5.5 0 01-.72.36c-2-.82-2.94-3-2.94-5.49 0-4.08 3.44-9 10.26-9 5.48 0 9.09 4 9.09 8.23.05 5.66-3.08 9.87-7.7 9.87z" />
      </symbol>
      <symbol id="ico-twitter" viewBox="0 0 14.747 12">
        <path d="M14.747 1.422a6.117 6.117 0 0 1-1.737.478A3.036 3.036 0 0 0 14.341.225a6.012 6.012 0 0 1-1.922.734 3.025 3.025 0 0 0-5.232 2.069 2.962 2.962 0 0 0 .078.691A8.574 8.574 0 0 1 1.025.553 3.032 3.032 0 0 0 1.966 4.6a2.955 2.955 0 0 1-1.379-.381v.038a3.028 3.028 0 0 0 2.428 2.969 3.046 3.046 0 0 1-.8.106 2.854 2.854 0 0 1-.569-.056 3.03 3.03 0 0 0 2.828 2.1 6.066 6.066 0 0 1-3.759 1.3 6.133 6.133 0 0 1-.722-.044A8.457 8.457 0 0 0 4.632 12a8.557 8.557 0 0 0 8.615-8.619c0-.131 0-.263-.009-.391a6.159 6.159 0 0 0 1.509-1.568Z" />
      </symbol>
      <symbol id="ico-email" viewBox="0 0 30 30">
        <path d="M3.08 5.45a3.14 3.14 0 00-2.27.81A3.15 3.15 0 000 8.52v13a3.12 3.12 0 00.82 2.26 3.18 3.18 0 002.28.81h23.84a3.14 3.14 0 002.26-.82 3.17 3.17 0 00.8-2.28v-13a3.1 3.1 0 00-.81-2.24 3.1 3.1 0 00-2.19-.8zm.33 2.05h23.18l-11.08 9a1 1 0 01-1 0zM2.05 9l7.34 6-7.34 6.22zM28 9v12.2L20.61 15zm-17 7.28c.85.64 1.67 1.42 2.53 2a3.35 3.35 0 003 0c.86-.61 1.69-1.34 2.53-2l7.3 6.22H3.69z" />
      </symbol>
      <symbol id="ico-telephone" viewBox="0 0 30 30">
        <path d="M5.36 3c1.9-1.47 3.54-1.21 4.32.52 1.56 3.11 2.68 4.67 1.56 5.88-2.08 1.39-3.55 2.08-2.77 3.6a18.17 18.17 0 008.22 8.83c1.39.86 2.17-.61 3.72-2.51 1.3-1 2.77.08 5.8 1.9 1.64.95 1.73 2.51.17 4.33C17.3 35.71-5.46 11.39 5.36 3z" />
      </symbol>

      {/* Link Tree Custom Icon */}
    </svg>
  )
}

export default memo(SVGSprite)
