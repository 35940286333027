const style = (theme) => {
  const output = {
    root: {},
    hero: {
      position: 'relative',
      width: '100vw',
      overflow: 'hidden',
      margin: [30, 'auto'],
    },
    heroMedia: {
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      margin: '10px auto',
      borderRadius: 100,
      '& > img': {
        display: 'block',
        maxWidth: 200,
      },
    },
    heroContent: {
      color: (props) => props.customTheme?.text_color,
      fontFamily: theme.fonts[1],
    },
    brandName: {
      width: 'fit-content',
      fontSize: 18,
      margin: 'auto',
    },
    brandSlogan: {
      width: 'fit-content',
      fontSize: 14,
      margin: [15, 'auto', 0, 'auto'],
      color: (props) => `${theme.getRgba(`${props.customTheme?.text_color}`, 0.5)}`,
    },

  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    wrapperHero: {
      '& h1': {
        fontSize: 80,
      },
    },
    intro: {
      extend: theme.smallWrapper,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-30%',
    },
    introImage: {
      width: '100%',
      flexBasis: '100%',
    },
    introContent: {
      marginTop: 40,
      paddingLeft: 0,
      paddingRight: 0,
      '& h2': {
        fontSize: 70,
      },
    },
    footer: {
      display: 'none',
    },
  }

  return output
}

export default style
