import { memo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const Spinner = () => {
  const classes = useStyles()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isLoading, layoutStyle } = useSelector((state) => ({
    isLoading: state.loading.isLoading,
    layoutStyle: state.layout.style,
  }), shallowEqual)

  return (
    <div
      className={classNames({
        [classes.spinner]: true,
        [classes.spinnerThemeInverse]: layoutStyle === 'alternative',
        [classes.visible]: isLoading,
      })}
    >
      <svg
        viewBox="0 0 50 50"
        className={classes.spinnerLoader}
      >
        <circle
          className={classes.spinnerPath}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="1"
        />
      </svg>
    </div>
  )
}

export default memo(Spinner)
