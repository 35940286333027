import { memo } from 'react'
import { createUseStyles } from 'react-jss'
import { removeParagraph } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const Hero = ({
  customTheme,
  image,
  name,
  slogan,
}) => {
  const classes = useStyles({ customTheme })

  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <div className={classes.heroMedia}>
          <img src={image} alt="" />
        </div>
        <div className={classes.heroContent}>
          <h1 className={classes.brandName} dangerouslySetInnerHTML={{ __html: removeParagraph(name) }} />
          <h2 className={classes.brandSlogan} dangerouslySetInnerHTML={{ __html: removeParagraph(slogan) }} />
        </div>
      </div>
    </div>
  )
}

export default memo(Hero)
