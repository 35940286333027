const style = (theme) => {
  const output = {
    LinktreeSocialsRoot: {
      width: '100%',
      padding: [0, 20],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: [25, 0],
      paddingBottom: 44,
    },
    icon: {
      width: 44,
      height: 44,
      margin: [0, 10, 0, 0],
      border: (props) => `1px solid ${theme.getRgba(`${props.customTheme?.socials_color}`, 0.2)}`,
      borderRadius: '50%',
      fillRule: 'evenodd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fill: 'white',
      '& svg': {
        display: 'block',
        maxWidth: 44,
        height: 12,
        fill: (props) => props.customTheme?.socials_color,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  }
  return output
}

export default style
