/* Why did you render */
// import './wdyr'
/* Polyfill */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
/* Accessibility */
import 'focus-visible'

import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import createStore, { history } from '@/base/store'
import '@/detects/modernizr'
import tracking from '@/tracking'
import App from '@/components/App'

tracking()
objectFitImages()

const store = createStore()

const renderApp = (AppComponent) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
          <AppComponent />
        </JssProvider>
      </ConnectedRouter>
    </Provider>
  )
}

ReactDOM.hydrate(
  renderApp(App),
  document.getElementById('root'),
)
