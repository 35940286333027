import Home from '@/pages/Home'
import NotFound from '@/pages/NotFound'
import { internazionalizePath } from '@/utils/path'

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
