/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const LinktreeLinks = ({
  elements,
  customTheme,
}) => {
  const [isSafari, setIsSafari] = useState(false)
  const $root = useRef()

  const classes = useStyles({ customTheme })
  const ua = navigator.userAgent.toLowerCase()

  useEffect(() => {
    if (customTheme) {
      $root.current.style.setProperty('--bounce-bg-color', customTheme.bounce_effect_background_color)
      $root.current.style.setProperty('--bounce-text-color', customTheme.bounce_effect_text_color)
    }
  }, [customTheme])

  useEffect(() => {
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        console.log('CURRENT BROWSER IS CHROME') // Chrome
      } else {
        console.log('CURRENT BROWSER IS SAFARI') // Safari
        setIsSafari(true)
      }
    }
  }, [])

  return (
    <div ref={$root} className={classes.LinktreeLinkRoot}>
      {elements.map((el, i) => {
        if (el.link !== '') {
          return (
            <div className={classes.linktreeLinkItem} key={i.toString()}>
              <a
                href={el?.link}
                className={classNames({
                  [classes.linktreeLink]: true,
                  [classes.active]: el.evidenza,
                  [classes.isSafari]: el.evidenza && isSafari,
                })}
              >
                <div className={classes.bannerImgContainer}>
                  <span className={classes.bannerImageLabel}>
                    {el?.label}
                  </span>
                  <div className={classes.bannerImageOverlay} />
                </div>
              </a>
              <span
                className={classes.labelDesktop}
              >
                ↳
                {' '}
                {el.short_description}
              </span>
            </div>
          )
        }
      })}

    </div>
  )
}

export default LinktreeLinks
