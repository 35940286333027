import { mainCacheAxios as axios } from '@/base/axios'
import { REQUEST_LOCALES, RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'
import { API } from '@/constants'

const requestLocales = () => ({ type: REQUEST_LOCALES })

const receiveLocales = (data) => ({
  type: RECEIVE_LOCALES,
  payload: data,
})

const fetchLocales = () => async (dispatch) => {
  dispatch(requestLocales())
  const response = await axios.get(API.LOCALES)
  dispatch(receiveLocales(response.data))
}

const setLanguage = () => {
  const currentLang = window.location.pathname.split('/').find((l) => l.length === 2)
  return ({
    type: SET_LANGUAGE,
    payload: currentLang,
  })
}

const setTranslations = (translations) => async (dispatch, getState) => {
  if (translations.length === 0) return
  const { languages } = getState().locale
  translations.forEach((translation) => languages[translation.locale.substr(0, 2)].url = translation.href)
  dispatch(receiveLocales(languages))
}

export {
  fetchLocales,
  setLanguage,
  setTranslations,
}
