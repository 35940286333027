import axios from 'axios'
import { API_BASE_URL } from '@/constants'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({ maxAge: 15 * 60 * 1000 }) // 15 minutes

const mainAxios = axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
})

const mainCacheAxios = axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
})

export {
  mainAxios,
  mainCacheAxios,
}
