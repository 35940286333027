import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import style from './style'

const useStyles = createUseStyles(style)

const LinktreeSocials = ({
  customTheme,
  elements,
}) => {
  const classes = useStyles({ customTheme })

  return (
    <div className={classes.LinktreeSocialsRoot}>
      {elements.facebook_link !== '' && (
        <div className={classes.icon}>
          <DelayLink
            to={elements.facebook_link}
            className={classes.LinktreeSocialLink}
          >
            <svg><use xlinkHref="#ico-facebook" /></svg>
          </DelayLink>
        </div>
      )}
      {elements.twitter_link !== '' && (
        <div className={classes.icon}>
          <DelayLink
            to={elements.twitter_link}
            className={classes.LinktreeSocialLink}
          >
            <svg><use xlinkHref="#ico-twitter" /></svg>
          </DelayLink>
        </div>
      )}
      {elements.instagram_link !== '' && (
        <div className={classes.icon}>
          <DelayLink
            to={elements.instagram_link}
            className={classes.LinktreeSocialLink}
          >
            <svg><use xlinkHref="#ico-instagram" /></svg>
          </DelayLink>
        </div>
      )}
      {elements.youtube_link !== '' && (
        <div className={classes.icon}>
          <DelayLink
            to={elements.youtube_link}
            className={classes.LinktreeSocialLink}
          >
            <svg><use xlinkHref="#ico-youtube" /></svg>
          </DelayLink>
        </div>
      )}
      {elements.linkedin_link !== '' && (
        <div className={classes.icon}>
          <DelayLink
            to={elements.linkedin_link}
            className={classes.LinktreeSocialLink}
          >
            <svg><use xlinkHref="#ico-linkedin" /></svg>
          </DelayLink>
        </div>
      )}

    </div>
  )
}
export default LinktreeSocials
