import {
  SET_LAYOUT_STYLE,
  SET_LAYOUT_BACK_PROP,
  RESET_LAYOUT_BACK_PROP,
} from '@/actions/types'

const initialState = {
  style: 'default',
  backButton: {
    label: 'Back',
    visible: false,
    link: '',
    callback: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_STYLE:
      state = {
        ...state,
        style: action.payload,
      }
      break
    case SET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: {
          ...state.backButton,
          [action.key]: action.payload,
        },
      }
      break
    case RESET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: initialState.backButton,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
