import {
  RECEIVE_GDRIVE_FILES,
  SET_CURRENT_GDRIVE_ITEM,
} from '@/actions/types'

import { composeBreadcrumb } from '@/utils/array'

const initialState = {
  currentFolder: {},
  isRoot: true,
  files: [],
  breadcrumb: [],
  currentItem: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_GDRIVE_FILES:
      state = {
        ...state,
        files: action.payload,
        isRoot: action.root,
        currentFolder: {
          id: action.key,
          title: action.name,
        },
        breadcrumb: composeBreadcrumb(
          { id: !action.root ? action.key : '', title: action.name },
          state.breadcrumb,
        ),
      }
      break
    case SET_CURRENT_GDRIVE_ITEM:
      state = {
        ...state,
        currentItem: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
